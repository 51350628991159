<template>
  <main>
    <v-container fluid>
      <template>
        <v-breadcrumbs>
          <template>
            <v-breadcrumbs-item>
              <v-icon color="primary">mdi-home</v-icon>
              <v-icon>mdi-chevron-double-right</v-icon> LISTA TIPOS
              <template v-if="mode == 'edit'">
                <v-icon>mdi-chevron-double-right</v-icon> Detalle tipo {{newCase.name}}
              </template>
              <template v-else>
                <v-icon>mdi-chevron-double-right</v-icon> Crear tipo
              </template>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </template>
      <v-card class="overflow-hidden">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <span class="green--text">Nombre</span>
              <v-text-field
                :error-messages="validationErrors.name"
                v-model="newCase.name"
                solo
                name="input-7-4"
                placeholder=""
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <div class="text-center mt-2 mb-2">
        <v-btn small class="mx-2" color="primary" rounded @click="save()">
          <v-icon>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </div>
    </v-container>
  </main>
</template>

<script>
import sweetalert from "sweetalert2";
import { mapState } from "vuex";
import {required} from "vuelidate/lib/validators";
import { updateTypes, createTypes} from "@/services/OrdinaryJustice";


export default {
  props: {
    mode: {
      default: "create"
    }
  },
  name: "CreateTypes",
  data() {
    return {
      newCase: {
        id: "",
        name: "",
      },
      eventId: "",
    };
  },
  validations() {
    return {
      newCase: {
        name: { required },
      },
    };
  },
  methods: {
    setEventData(eventData){
      this.newCase = eventData;
    },  
    async save() {
      this.$v.newCase.$touch();
      if (this.$v.newCase.$invalid) return;
      try {
        this.showLoader();

        const eventRequestData = {
          name: this.newCase.name,
        };

        this.mode === "edit"
            ? await updateTypes(eventRequestData, this.newCase.id)
            : await createTypes(eventRequestData);

        let varSuccess = this.mode === "edit"
            ? "actualizado"
            : "creado"

        await sweetalert.fire(
          `Registro ${varSuccess} correctamente`,
          "",
          "success"
        );

        this.hideLoader();

        this.$router.push({
            name: "ordinary-justice.valueReferences"
        });

      } catch (err) {
        this.hideLoader();
        console.error(err);
        sweetalert.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
  },
  async created() {
    this.showLoader();
    
    this.hideLoader();
  },
  computed: {
    validationErrors() {
      return {
        name: [
          ...[
            !this.$v.newCase.name.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
      };
    },
    ...mapState(["dataSource", "user"]),
  },
  components: {},
};
</script>

<style scoped></style>
